<template>
   <Card title="Assigned Tenant">
        <div v-if="loadingStates.fetchingDetails" class="flex flex-1 items-center justify-center py-14">
            <Loader />
        </div>

        <div v-else class="flex-1 w-full p-4 overflow-y-auto scroll-bar flex">
            <List :showTitle="false" :listItems="getTenantsList" :showSelectAll="true" :showSearch="true" @handleSelect="$emit('selectAll', {select: $event, type: 'tenant'})" @handleFilterResult="handleFilterResult($event)">
                <template #listheader>
                    <div class="px-4 py-1 grid grid-cols-2 text-gray-600 text-sm font-semibold">
                        <span>Tenant Name</span>
                    </div>
                </template>
                <template #item="{ item }">
                    <TenantListItem :showLicenseAdd="false" :tenant="item" @tenantAssign="$emit('tenantAssign', $event)" />
                </template>
            </List>
        </div>
    </Card>
</template>

<script>
import Card from "@shared/components/card";
import TenantListItem from "@shared/components/license-pack/tenant-list-item";
import Loader from "@shared/loader";
import List from "@shared/components/lists";

export default {
    name: "assign-tenant-check",
    components: {
        Card,
        TenantListItem,
        Loader,
        List,
    },
    props: {
        licenseData: {
            type: Object,
            default: () => {},
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
        getTenantsList: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        handleFilterResult(event) {
            this.$emit("handleFilterResult", { data: event, type: "tenants" });
        },
    }
}
</script>

<style>

</style>