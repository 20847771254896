<template>
    <Card showActionBtn title="Feature List">
        <div class="flex flex-1 p-4 overflow-y-auto max-h-full h-full">
            <div v-if="loadingStates.fetchingDetails" class="flex flex-1 items-center justify-center py-14">
                <Loader />
            </div>
            <List v-else :showTitle="false" :listItems="getFeaturedList" :showSelectAll="true" :showSearch="true" @handleSelect="$emit('selectAll', {select: $event, type: 'feature'})" @handleFilterResult="handleFilterResult($event)">
                <template #item="{ item }">
                    <FeatureListItem :item="item" @toggleEvent="$emit('licenseReadOnlyPermission', $event)"   />
                </template>
            </List>
        </div>
    </Card>
</template>

<script>
import Card from "@shared/components/card";
import List from "@shared/components/lists";
import FeatureListItem from "@shared/components/license-pack/feature-list-item";
import Loader from "@shared/loader";

export default {
    components: {
        Card,
        List,
        FeatureListItem,
        Loader,
    },
    props: {
        licenseData: {
            type: Object,
            default: () => {},
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
        getFeaturedList:{
            type:Array,
            default: () => []
        }
    },
}
</script>

<style>

</style>