<template>
    <div v-if="checkData" class="shadow-md relative rounded-2xl bg-card-bg flex gap-6 flex-col">
        <div class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl">
            Check Details
            <button v-allow="'check.delete'" v-if="this.$route.params.action !== 'view'" @click="deleteCheck(checkData)" :disabled="this.$route.params.action == 'view'" class="flex btn btn-outline bg-red-500 text-white-text btn-circle hover:bg-red-600 border-none btn-sm">
                <font-awesome-icon icon="trash" />
            </button>
        </div>
        <div class="flex flex-col gap-3 p-3">
            <div class="flex w-full gap-6 items-stretch">
                <div class="flex flex-col w-2/5 gap-3">
                    <div class="flex flex-col gap-3 w-full">
                        <FormulateInput 
                            class="w-full text-base-content" 
                            label="Check Name" 
                            placeholder="Check Name" 
                            type="text"
                            validation="required" 
                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" 
                            :disabled="this.$route.params.action == 'view'" 
                            @input="handleInput"
                            v-model="checkData.name" 
                        />
                    </div>
                    <div class="flex flex-col gap-3">
                        <div class="flex items-center gap-5">
                            <FormulateInput 
                                class="w-full text-base-content" 
                                label="SLA Period" 
                                placeholder="SLA Period (days)" 
                                type="text" 
                                min="1" 
                                validation="required|number|min:1,number" 
                                :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" 
                                :disabled="this.$route.params.action == 'view'" 
                                @input="handleInput"
                                v-model="checkData.sla" 
                            />
                            <span class="text-base-content text-md font-semibold w-0.5/4">Days</span>
                        </div>
                    </div>
                    <div class="flex flex-col gap-3 w-full">
                        <span class="text-base-content text-md font-bold"> Check Category </span>
                        <span class="w-full text-base-content">{{ checkData.category }}</span>
                    </div>
                    <div class="flex flex-col gap-3 w-full">
                        <span class="text-base-content text-md font-bold"> Check Type </span>
                        <span class="w-full text-base-content">{{ checkData.subtype_name }}</span>
                    </div>
                </div>
                <div class="flex flex-col w-3/5 gap-3">
                    <div class="flex flex-col gap-3 w-full">
                        <FormulateInput
                            v-model="entityTypesModel"
                            @input="handleInput"
                            validation="required"
                            class="w-full"
                            label="Entity Type"
                            placeholder="Select Entity Type"
                            type="multi-select"
                            variant="alt"
                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                            :options="entityTypeOptions"
                            :loading="isEntityTypesLoading"
                            :config="{
                                ...ENTITY_TYPES_DROPDOWN_CONFIG,
                                limit: 4,
                            }"
                            :disabled="this.$route.params.action == 'view'"
                        />
                    </div>
                    <div class="flex flex-col gap-3 w-full h-full">
                        <FormulateInput 
                            class="w-full" 
                            :rows="10" 
                            label="Check Description" 
                            placeholder="Check Description" 
                            type="textarea" validation="required" 
                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" 
                            :disabled="this.$route.params.action == 'view'"
                            @input="handleInput" 
                            v-model="checkData.description" 
                        />
                    </div>
                </div>
            </div>
            <div v-if="checkData && checkData.parameters && checkData.parameters.parameters">
                <span class="text-base-content text-md font-bold"> Check Parameters </span>
                <div class="mt-2">
                    <div class="rounded flex gap-2 items-center" v-for="params in checkData.parameters.parameters" :key="params.name">
                        <div class="bg-indigo-200 py-1 px-2 text-base-content rounded">
                            {{ params.name }}
                        </div>
                        <input 
                            v-model="params.value"
                            @input="handleInput" 
                            :type="params.value_type" 
                            min="0" 
                            @keypress="isNumber" 
                            class="text-center border border-b border-gray-200 h-8 w-12 rounded flex items-center justify-center"
                        />
                    </div>
                </div>
            </div>
            <div class="mt-2 flex justify-end max-w-full gap-4" v-allow="'check.edit'" v-if="this.$route.params.action !== 'view'">
                <Button 
                    @click="saveCheck()" 
                    class="btn-primary float-right mt-4" 
                    text="Save" 
                    :disabled="this.$route.params.action == 'view'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/button";
import { isNumber } from "@/plugins/functions";
import { mapActions, mapState } from 'vuex';
import { ENTITY_TYPES_DROPDOWN_CONFIG } from "@shared/utils/constants";

export default {
    name: "check-details",
    components: {
        Button,
    },
    props: {
        checkData: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            ENTITY_TYPES_DROPDOWN_CONFIG,
            isEntityTypesLoading: false,
        }
    },
    computed: {
        ...mapState({
            entityTypeOptions: 'entityTypeList',
            isDirty: state => state.checksAdmin.isDirty
        }),
        entityTypesModel: {
            get() {
                return this.checkData?.entity_types
            },
            set(val) {
                if (!val.length) {
                    this.$toast.error("At least one entity type required.");
                } else {
                    this.checkData.entity_types = val;
                }
            },
        },
    },
    methods: {
        ...mapActions([
            'fetchEntityTypeList',
            'handleCheckDataChange'
        ]),
        isNumber,
        handleInput() {
            this.handleCheckDataChange(this.checkData);
        }, 
        saveCheck() {
            this.$emit("handleSaveCheck");
        },
        deleteCheck(checkDetails) {
            this.$emit("handleDeleteCheck", checkDetails);
        },
        async fetchEntityTypeOptions() {
            this.isEntityTypesLoading = true;
            if (!this.entityTypeOptions.length) {
                await this.fetchEntityTypeList();
            }
            this.isEntityTypesLoading = false;
        },
    },

    async mounted() {
        await this.fetchEntityTypeOptions();
    },
};
</script>

<style></style>
